<template>
  <IndexMenu menuItem="Do what you can, with what you have, where you are"></IndexMenu>
</template>

<script>
import IndexMenu from './components/IndexMenu.vue'

export default {
  name: 'App',
  components: {
    IndexMenu,
  }
}
</script>

<style>
#app { }
</style>
